import React from "react"
import NavBar from "../components/NavBar"
import Metatags from "../components/metatags"
import Footer from "../components/Footer.en"

const IndexES = ({ location }) => {
  return (
    <>
      <Metatags
        title="Multilógica | Elektroteks Adquiere Mammut"
        description="Proveedor de maquinaria para colchones"
        url="https://newsite.multilogica.ind.br/es/privacy-policy"
        image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
        keywords="colchón, máquina de coser, mammut, beckmann sew"
        language="es"
      />
      <NavBar location={location["pathname"]} />
      <div className="h-20" />
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-4 mx-2">
          <div className="col-span-2" />
          <div className="col-span-8 text-center">
            <div className="text-5xl font-semibold text-mi-blue">
              Elektroteks Adquiere Mammut
            </div>
            <div className=" h-8" />
            <div className=" text-left text-xl">
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>
                <b>
                  Bursa, Turquía / Colonia, Alemania - [2 de octubre de 2024]
                </b>{" "}
                - Elektroteks, el mayor fabricante mundial de maquinaria para
                colchones y espumas, se enorgullece de anunciar la adquisición
                del 100% de las acciones de Mammut (Nähmaschinenfabrik Emil
                Stutznäcker GmbH & Co KG), líder mundial en la fabricación de
                máquinas de acolchado. Con más de 140 años de historia, Mammut
                es conocida por producir las máquinas de acolchado de la más
                alta calidad, estableciendo el estándar de precisión y artesanía
                en la industria. El negocio se cerró oficialmente el 23 de
                septiembre.
              </p>
              <p>&nbsp;</p>
              <p>
                <b>Elektroteks</b>, con sede en Bursa, Turquía, es una empresa
                familiar con presencia global en 133 países. Como mayor
                productora de máquinas para las industrias de colchones y
                espumas, Elektroteks ha construido una reputación de innovación,
                satisfacción del cliente y una robusta red de servicios
                globales. Con 350 empleados y una moderna instalación de
                producción de 65.000 m², la empresa continúa ofreciendo
                soluciones líderes en la industria.
              </p>
              <p>&nbsp;</p>
              <p>
                <b>Mammut</b>, con sede en Colonia, Alemania, está a la
                vanguardia de la tecnología de acolchado desde hace más de un
                siglo, fabricando las máquinas de acolchado más precisas del
                mundo. Elektroteks está totalmente comprometida en preservar
                este legado mientras infunde nueva energía e ideas en la marca
                Mammut.
              </p>
              <p>&nbsp;</p>
              <p>
                <b>
                  Michael Pavlidis permanecerá como Director General de Mammut
                </b>
                , garantizando continuidad y liderazgo durante este nuevo y
                emocionante capítulo. Su experiencia y dedicación serán
                cruciales para conducir la empresa a través de proyectos e
                innovaciones futuros.
              </p>
              <p>&nbsp;</p>
              <p>
                <b>Serkan Guler, CEO de Elektroteks</b>, declaró: “Esta
                adquisición es una combinación poderosa de dos líderes de la
                industria. Estamos honrados de continuar el legado de Mammut, el
                nombre más antiguo y respetado en máquinas de acolchado, y
                esperamos colaborar en nuevos proyectos que traerán soluciones
                innovadoras al mercado.”
              </p>
              <p>&nbsp;</p>
              <p>
                Como parte de esta integración, Elektroteks ampliará las
                operaciones de repuestos y servicios de Mammut utilizando su
                extensa red global. La sede y la producción de Mammut
                permanecerán en Alemania, preservando su fuerte legado. Nuevos
                proyectos emocionantes ya están en marcha, prometiendo mejorar
                aún más las ofertas de Mammut en la industria de máquinas de
                acolchado.
              </p>
              <p>&nbsp;</p>
              <p>
                La adquisición de Mammut por parte de Elektroteks anuncia una
                nueva era de crecimiento e innovación, aprovechando las
                fortalezas de ambas empresas para proporcionar productos y
                servicios superiores a las industrias globales de colchones y
                espumas.
              </p>
              <p>&nbsp;</p>
              <p>
                <b>Para má informaciones</b>, por favor contacte:
                <br />
                info@elektroteks.com
                <br />
                sales@mammut.de
              </p>
            </div>
          </div>
          <div className="h-10" />
        </div>
      </div>
      <div className="h-20" />
      <div className="h-10" />
      <Footer />
    </>
  )
}

export default IndexES
